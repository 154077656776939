import { httpClient } from '../../config';

export const fetchOldestEventTime = async () => {
  try {
    const response = await httpClient('/tables/meta_api_data/oldest-event-time');
    return JSON.parse(response.body).oldestEventTime;
  } catch (error) {
    console.error('Error fetching oldest event time:', error);
    return null;
  }
};
