import { Edit } from 'react-admin';
import { MediaPlatformSettingsForm } from './MediaPlatformSettings.Form';
import { BackButton } from '../../../helperComponents/BackButton';

export const MediaPlatformSettingsEdit = (props) => {
  return (
    <>
      <BackButton path="/media_platform_api_settings" />
      <span className="h4">Edit Media Platform API Settings</span>
      <Edit {...props} title=" " redirect={false}>
        <MediaPlatformSettingsForm isEdit={true} />
      </Edit>
    </>
  );
};
