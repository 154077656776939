import {
  TextInput,
  SelectInput,
  required,
  AutocompleteInput
} from 'react-admin';
import { ACTION_SOURCE_CHOICES } from '../../../../../config/constants/apiConstants';
import { countries } from '../../../../../config/constants/countries';

export const MainTab = ({ isEdit }) => {
  return (
    <>
      {isEdit && <TextInput source="id" label="ID" disabled={true} />}
      <TextInput
        source="domain_path"
        label="Domain Path"
        className="custom-input-width"
        validate={required()}
      />
      <TextInput
        source="fb_pixel_id"
        label="Facebook pixel ID"
        className="custom-input-width"
        validate={required()}
      />
      <TextInput
        source="fb_api_key"
        label="Facebook API key"
        fullWidth
        multiline
        validate={required()}
      />
      <TextInput
        source="event_source_url"
        label="Event source URL"
        className="custom-input-width"
        validate={required()}
      />
      <SelectInput
        source="action_source"
        label="Action Source"
        choices={ACTION_SOURCE_CHOICES}
        className="custom-input-width"
        validate={required()}
      />
      <AutocompleteInput
        source="country"
        label="Country"
        choices={countries}
        className="custom-input-width"
        optionText="name"
        optionValue="id"
        helperText="Will apply to Tune data and TrackCB data if missing."
      />
    </>
  );
};
