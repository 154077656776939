import { useEffect } from 'react';
import {
  DatagridConfigurable,
  List,
  TextField,
  ReferenceField,
  TextInput,
  FunctionField,
} from 'react-admin';
import { Tooltip } from '@mui/material';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { DocumentationButton } from '../../../helperComponents/DocumentationButton';
import { ToolBarRowStyleToggle } from '../../../helperComponents/ToolBarRowStyleToggle';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { AffiliateNamesField } from '../../../helperComponents/AffiliateNamesField';
import { MediaPlatformApiSettings } from '../../../../helpers/constants';
import { setDefaultColumns } from '../../../../utils/localStorageUtils';
import { BulkAction } from '../../../../components/helperComponents/BulkAction';

export const MediaPlatformSettingsList = (props) => {
  const rowClassName = useRowStyle();

  //Workaround because MediaPlatformApiSettings.defaultOffColumns-platform doesn't work because it's source is internal_account_id
  useEffect(() => {
    setDefaultColumns({
      preferenceKey: 'media_platform_api_settings.datagrid.columns',
      defaultColumns: ["0", "1", "2", "3", "4", "5", "6", "7"]
    });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <TitleAboveTable title="Media platform API settings" />
        <DocumentationButton
          reference="media-platform-api-documentation"
          label="Media platform API settings Documentation"
        />
      </div>
      <List
        {...props}
        title=" "
        actions={<ToolBarRowStyleToggle showSelectColumnsButton />}
        pagination={<TablePagination />}
        filters={[
          <TextInput
            source="q"
            label="ID, Internal Account ID, Affiliate ID, Domain Path, Facebook Pixel ID"
            alwaysOn
            sx={{ width: '700px' }}
          />,
          <TextInput
            source="fb_pixel_id"
            label="Facebook Pixel ID"
            className="custom-input-width"
          />,
          <TextInput
            source="fb_api_key"
            label="Facebook API key"
            className="custom-input-width"
          />,
          <TextInput
            source="event_source_url"
            label="Event source URL"
            className="custom-input-width"
          />,
        ]}
      >
        <DatagridConfigurable
          rowClick="edit"
          className={`${rowClassName} datagrid-content`}
          omit={MediaPlatformApiSettings.defaultOffColumns}
          bulkActionButtons={<BulkAction showCopyButton={true} showDeleteButton={true} />}
        >
          <TextField source="internal_account_id" label="Internal Account ID" />
          <ReferenceField
            source="internal_account_id"
            reference="reference_fields/platform_account_info"
            label="Account ID"
          >
            <FunctionField
              render={record => (
                <Tooltip title={record.account_name || ''} placement="top-start" arrow>
                  <span>{record.account_id}</span>
                </Tooltip>
              )}
            />
          </ReferenceField>
          <FunctionField
            label="Tune Affiliate IDs"
            render={(record) => (
              <AffiliateNamesField affiliate_ids={record.affiliate_ids} />
            )}
            source="affiliate_ids"
          />
          <TextField source="event_source_url" label="Event source URL" />
          <TextField source="domain_path" label="Domain Path" />
          <TextField source="fb_pixel_id" label="Facebook pixel ID" />
          <FunctionField
            source="fb_api_key"
            label="Facebook API key"
            render={record => (
              <div style={{ width: '280px', wordWrap: 'break-word' }}>
                {record.fb_api_key}
              </div>
            )}
          />
          <TextField source="payout_percentage" label="Tune Payout Percentage" />
          <TextField source="payout_percentage_trackcb" label="TrackCB Payout Percentage" />
          <TextField source="id" label="ID" />
          <ReferenceField
            source="internal_account_id"
            reference="reference_fields/platform_account_info"
            label="Platform"
            link={false}
          >
            <TextField source="platform" />
          </ReferenceField>
          <TextField source="action_source" label="Action Source" />
          <TextField source="tune_purchase" label="Tune Purchase" />
          <TextField source="tune_purchase_cpa" label="Tune Purchase CPA" />
          <FunctionField
            label="TrackCB Affiliate IDs"
            render={(record) => (
              <AffiliateNamesField affiliate_ids={record.affiliate_ids_trackcb} />
            )}
            source="affiliate_ids"
          />
          <TextField source="brand_id" label="Brand ID" />
        </DatagridConfigurable>
      </List>
    </>
  );
};
