import { useEffect, useState } from 'react';
import {
  DatagridConfigurable,
  List,
  TextField,
  SelectColumnsButton,
  FilterButton
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { parse } from 'query-string';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { DocumentationButton } from '../../../helperComponents/DocumentationButton';
import { EmptyBulkActionButtons } from '../../../helperComponents/EmptyBulkActionButtons';
import { AggregateBySelect } from '../../../helperComponents/AggregateBySelect';
import { DateRangeFilter } from '../../../helperComponents/DateRangeFilter';
import { ExportAllDataButton } from './components/ExportAllDataButton';
import { ActionsWithoutCreate } from '../../settings/cron_settings/helpers/ActionsWithoutCreate';
import { useRowStyle } from '../../../../hooks/useRowStyle';
import { getMetaApiDataAggregatedFilters } from '../../../../utils/filters/metaApiDataFilters';
import { fetchAccounts } from '../../../../utils/accountUtils';
import { MetaApiData } from '../../../../helpers/constants';
import { getFilterParamsFromLocalStorage } from '../../../../utils/localStorageUtils';
import { loadInitialDates } from '../../../../utils/dateUtils';
import { getAggregateValue } from '../../../../utils/aggregatedTablesUtils';

export const AggregatedMetaApiDataList = (props) => {
  const rowClassName = useRowStyle();
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [firstRecordTime, setFirstRecordTime] = useState(null);

  const handleRowClick = (id, basePath, record) => {
    const queryParams = parse(window.location.search);
    const currentFilters = queryParams.filter ? JSON.parse(queryParams.filter) : {};
    const aggregateValue = getAggregateValue('meta_api_data');
    const { startDate, endDate } = getFilterParamsFromLocalStorage('meta_api_data/aggregated-pixel') || {};
  
    const newFilters = { 
      ...currentFilters, 
      [aggregateValue]: record[aggregateValue],
      ...(startDate ? { start_date: startDate } : {}),
      ...(endDate ? { end_date: endDate } : {})
    };

    navigate({
      pathname: '/meta_api_data',
      search: `?filter=${encodeURIComponent(JSON.stringify(newFilters))}&displayedFilters=${encodeURIComponent(JSON.stringify({ ...queryParams.displayedFilters }))}`,
    });
  };

  useEffect(() => {
    const loadAccounts = async () => {
      const fetchedAccounts = await fetchAccounts();
      setAccounts(fetchedAccounts);
    };

    loadAccounts();
  }, []);

  useEffect(() => {
    const loadDates = async () => {
      const { dateRange, firstRecordTime } = await loadInitialDates('meta_api_data/aggregated-pixel');
      setDateRange(dateRange);
      setFirstRecordTime(firstRecordTime);
    };

    loadDates();
  }, [localStorage.getItem('aggregate')]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <TitleAboveTable title="Meta API Data" />
      </div>
      <List
        {...props}
        title=" "
        actions={<ActionsWithoutCreate
          showExportButton={false}
          showFilterButton={false}
          AdditionalButton={
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between' }}>
                <DateRangeFilter dateRange={dateRange} firstRecordTime={firstRecordTime} />
                <DocumentationButton reference="meta-api-documentation" label="Meta Data API Documentation" />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AggregateBySelect tableName='meta_api_data' localStorageName='meta_api_data/aggregated-pixel' />
                <SelectColumnsButton preferenceKey="meta_api_data.datagrid" />
                <ExportAllDataButton isAggregated />
                <FilterButton/>
              </div>
            </div>
          }
        />}
        pagination={<TablePagination />}
        perPage={25}
        filters={getMetaApiDataAggregatedFilters(accounts)}
      >
        <DatagridConfigurable
          rowClick={handleRowClick}
          bulkActionButtons={<EmptyBulkActionButtons/>}
          preferenceKey="meta_api_data.datagrid"
          omit={MetaApiData.defaultOffColumns}
          className={`${rowClassName} datagrid-content`}
        >
          <TextField source='count' label="Count" />
          <TextField source="data_source" label="Data Source" />
          <TextField source="settings_id" label="Settings ID" />
          <TextField source="pixel_id" label="FB Pixel ID" />
          <TextField source="brand_id" label="Brand ID" />
          <TextField source="order_id" label="Order ID" />
          <TextField source="fb_event_id" label="Event ID" />
          <TextField label="Internal Account ID" />
          <TextField label="Account ID" />
          <TextField source="account_name" label="Account Name" />
          <TextField label="Campaign ID" />
          <TextField label="Transaction ID" />
          <TextField label="Event Name" />
          <TextField label="Value" />
          <TextField label="Payout Percentage" />
          <TextField label="Event Time" />
          <TextField label="API Response" source="api_response" />
        </DatagridConfigurable>
      </List>
    </>
  );
};
