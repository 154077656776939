import { makePostRequest } from './loaders';
import { RAW_DATA_STATUSES, RAW_DATA_ENDPOINTS } from '../config/constants';

export const generateReport = async ({ requestBody, endpoint, setStatus }) => {
  try {
    const response = await makePostRequest(endpoint, requestBody);
    const { status } = JSON.parse(response.body);
    setStatus(status);
  } catch (error) {
    console.error('Error generating report:', error);
  }
};

export const constructDownloadUrl = (endpoint, params) => {
  const query = new URLSearchParams(params).toString();
  return `${endpoint}?${query}`;
};

export const initiateDownload = ({ endpoint, params, setStatus, notFoundStatus }) => {
  const downloadUrl = constructDownloadUrl(endpoint, params);
  window.location.href = downloadUrl;
  setStatus(notFoundStatus);
};

export const checkDownloadStatus = async ({ setStatus, endpoint, notFoundStatus }) => {
  try {
    const response = await makePostRequest(endpoint);
    const { status } = JSON.parse(response.body);
    setStatus(status);
  } catch (error) {
    console.error('Error checking download status:', error);
    setStatus(notFoundStatus);
  }
};
