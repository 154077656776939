import {
  SelectInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  NumberInput,
  required,
  BooleanInput,
} from 'react-admin';
import { CustomDisplayField } from '../../../../helperComponents/CustomDisplayField';
import { CustomPaper } from '../../../../helperComponents/CustomPaper';
import { useAffiliates } from '../../../../../hooks/useAffiliates';
import { usePlatformAccounts } from '../../../../../hooks/usePlatformAccounts';
import {
  TUNE_PURCHASE_CHOICES,
  TUNE_GOAL_NAMES,
  FB_EVENT_NAMES,
  AFFILIATE_OPTIONS_TUNE,
  EVENT_TYPE_OPTIONS
} from '../../../../../config/constants/apiConstants';
import { JsonBooleanInput } from '../../../../helperComponents/JsonBooleanInput';

export const TuneTab = () => {
  const affiliates = useAffiliates();
  const platformAccounts = usePlatformAccounts();

  return (
    <>
      <SelectInput
        source="internal_account_id"
        label="Internal Account ID"
        choices={platformAccounts}
        className="custom-input-width"
        helperText="Account must be selected for Tune data to be captured."
      />
      <FormDataConsumer>
        {({ formData }) => {
          const account = platformAccounts.find((account) => account.id === formData.internal_account_id) || {};
          return (
            <>
              <CustomDisplayField
                label="Platform"
                value={account.platform}
                isDisabled={true}
              />
              <CustomDisplayField
                label="Account ID"
                value={account.account_id}
                isDisabled={true}
              />
            </>
          );
        }}
      </FormDataConsumer>
      <SelectInput
        source="affiliate_option_tune"
        label="Affiliate Option"
        choices={AFFILIATE_OPTIONS_TUNE}
        className="custom-input-width"
      />
      <FormDataConsumer>
        {({ formData }) => (
          <AutocompleteArrayInput
            label="Affiliate IDs"
            source="affiliate_ids"
            choices={affiliates.map((affiliate) => ({
              id: affiliate.affiliate_id,
              name: `${affiliate.affiliate_id} - ${affiliate.affiliate}`,
            }))}
            PaperComponent={(props) => (
              <CustomPaper
                {...props}
                width="670px"
                elevation={8}
                margin="0 0 0 150px"
              />
            )}
            style={{ minWidth: '600px' }}
            disabled={formData.affiliate_option_tune !== 1}
          />
        )}
      </FormDataConsumer>
      <SelectInput
        source="event_type_option_tune"
        label="Event Type Option"
        choices={EVENT_TYPE_OPTIONS}
        className="custom-input-width"
      />
      <FormDataConsumer>
        {({ formData }) => (
          <AutocompleteArrayInput
            label="Event Types"
            source="event_types_tune"
            choices={TUNE_GOAL_NAMES}
            PaperComponent={(props) => (
              <CustomPaper
                {...props}
                width="670px"
                elevation={8}
                margin="0 0 0 150px"
              />
            )}
            style={{ minWidth: '600px' }}
            disabled={formData.event_type_option_tune !== 1}
          />
        )}
      </FormDataConsumer>
      <SelectInput
        source="tune_purchase"
        label="Tune Purchase"
        choices={TUNE_PURCHASE_CHOICES}
        className="custom-input-width"
        validate={required()}
        defaultValue={FB_EVENT_NAMES.PURCHASE}
      />
      <SelectInput
        source="tune_purchase_cpa"
        label="Tune Purchase CPA"
        choices={TUNE_PURCHASE_CHOICES}
        className="custom-input-width"
        validate={required()}
        defaultValue={FB_EVENT_NAMES.PURCHASE_CPA}
      />
      <div className="d-flex">
        <JsonBooleanInput 
          source="add_tune_event_id"
          jsonSource="event_id_settings"
          label="Add Event ID (TID-OrderID)"
        />
        <BooleanInput source="add_tune_external_id" label="Add External ID (Transaction ID)"/>
        <BooleanInput source="add_tune_order_id" label="Add Custom Data Order ID (Brand name - Order ID)"/>
      </div>
      <NumberInput
        source="payout_percentage"
        step={0.01}
        label="Payout Percentage"
        helperText="Enter payout percentage as a decimal (e.g., 0.50 for 50%). This value will be taken only from Payout and not Revenue."
        className="custom-input-width"
        defaultValue={1}
        validate={required()}
      />
    </>
  );
};
