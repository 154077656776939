import {
  FormDataConsumer,
  BooleanInput,
} from 'react-admin';

export const JsonBooleanInput = ({ source, label, jsonSource }) => (
  <FormDataConsumer>
    {({ formData, ...rest }) => (
      <BooleanInput
        label={label}
        source={jsonSource}
        {...rest}
        format={value => value ? value[source] : false}
        parse={value => ({ ...formData[jsonSource], [source]: value })}
      />
    )}
  </FormDataConsumer>
);
