import React, { useEffect, useState } from 'react';
import {
   Datagrid,
   DateField,
   List,
   TextField,
   SelectInput,
   TextInput,
   DateInput,
   FunctionField,
   SelectArrayInput,
} from 'react-admin';
import { actionNames, millisToMinutesAndSeconds, setChoices } from '../../../helpers';
import { TitleAboveTable } from '../../helperComponents/TitleAboveTable';
import { TablePagination } from '../../helperComponents/TablePagination';
import { getUserTimeZone } from '../../../helpers/getUserTimeZone';
import { httpClient } from '../../../config';
import { EmptyBulkActionButtons } from '../../helperComponents/EmptyBulkActionButtons';
import { Tooltip } from '@mui/material';
import { useRowStyle } from '../../../hooks/useRowStyle';
import { ToolBarRowStyleToggle } from '../../helperComponents/ToolBarRowStyleToggle';

export const ActionsLogsList = props => {
   const [userNames, setUserNames] = useState([]);
   const [reports, setReports] = useState([]);

   const rowClassName = useRowStyle();

   useEffect(() => {
      try {
         const doRequest = async () => {
            const responseUserNames = await httpClient('/tables/allowed/reference_fields/user_names');
            const responseReports = await httpClient('/tables/allowed/reference_fields/report_names');

            const fetchedUserNames = JSON.parse(responseUserNames.body) || [];
            const fetchedReports = JSON.parse(responseReports.body) || [];

            setUserNames(fetchedUserNames);
            setReports(fetchedReports);
         };

         doRequest();
      } catch (err) {
         console.log(err);
      }
   }, []);

   return (
     <>
        <TitleAboveTable title="Action logs"/>
        <List {...props}
              actions={<ToolBarRowStyleToggle/>}
              pagination={<TablePagination/>}
              filters={[
                 <SelectArrayInput source="user_names_filter"
                                   label="User names"
                                   choices={userNames.map(({username}) => ({
                                      id: username,
                                      name: username,
                                   }))}
                 />,
                 <SelectInput source="action" label="Action" choices={setChoices(actionNames)}/>,
                 <TextInput source="report" label="Report CSV"/>,
                 <SelectArrayInput source="reports_filter"
                                   label="Reports"
                                   choices={reports.map(({id, report_name}) => ({
                                      id: report_name,
                                      name: `${report_name} - ${id}`,
                                   }))}
                 />,
                 <DateInput source="date_filter" label="Date"/>,
                 <TextInput source="account_id_filter" label="Account ID"/>,
                 <DateInput source="start_date_filter" label="Start Date"/>,
                 <DateInput source="end_date_filter" label="End Date"/>,
              ]}
              title=" "
              sort={{field: 'date', order: 'DESC'}}>
           <Datagrid bulkActionButtons={<EmptyBulkActionButtons/>} className={rowClassName}>
              <TextField source="id" label="ID"/>
              <TextField source="user_name" label="User name"/>
              <TextField source="action"/>
              <TextField source="report"/>
              <FunctionField
                label="Account | ID"
                render={record => record.account ? <span>{record.account} | {record.account_id}</span> : null}
              />
              <FunctionField
                label={
                 <Tooltip placement="right-end" title="Time To Complete">
                   <div>TTC</div>
                 </Tooltip>}
                sortBy="time_to_complete"
                sortByOrder="ASC"
                render={({time_to_complete}) => time_to_complete ? millisToMinutesAndSeconds(time_to_complete) : null}/>
              <DateField source="date"
                         label="Time"
                         showTime={true}
                         showDate={false}
                         locales="he-IL"
                         options={{
                            timeZone: getUserTimeZone() || 'Asia/Jerusalem',
                         }}
              />
              <DateField source="date"
                         label="Date"
                         showTime={false}
                         showDate={true}
                         locales="he-IL"
                         options={{
                            timeZone: getUserTimeZone() || 'Asia/Jerusalem',
                         }}
              />
              <TextField source="report_date_range" label="Report date range"/>
           </Datagrid>
        </List>
     </>
   );
};
