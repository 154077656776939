import { useState, useEffect } from 'react';
import { useListContext } from 'react-admin';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {
  handleSelectDateRange,
  formatDateRange,
  createCustomStaticRanges
} from '../../utils/dateUtils';

export const DateRangeFilter = ({ dateRange, firstRecordTime }) => {
  const { setFilters, filterValues } = useListContext();
  const [internalDateRange, setInternalDateRange] = useState([
    {
      startDate: dateRange?.startDate ? new Date(dateRange.startDate) : '',
      endDate: dateRange?.endDate ? new Date(dateRange.endDate) : new Date(),
      key: 'selection',
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    if (dateRange?.startDate || dateRange?.endDate) {
      setInternalDateRange([
        {
          startDate: dateRange.startDate ? new Date(dateRange.startDate) : '',
          endDate: dateRange.endDate ? new Date(dateRange.endDate) : new Date(),
          key: 'selection',
        },
      ]);
    }
  }, [dateRange]);

  const customStaticRanges = [
    ...defaultStaticRanges,
    ...createStaticRanges(createCustomStaticRanges(firstRecordTime)),
  ];

  return (
    <div
      onMouseEnter={() => setShowCalendar(true)}
      onMouseLeave={() => setShowCalendar(false)}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      <FormControl variant="outlined" size="small" style={{ margin: '0 10px', width: '280px' }}>
        <InputLabel htmlFor="date-range">Date Range</InputLabel>
        <OutlinedInput
          id="date-range"
          value={`${formatDateRange(internalDateRange[0].startDate)} - ${formatDateRange(internalDateRange[0].endDate)}`}
          readOnly
          label="Date Range"
          style={{ cursor: 'pointer' }}
        />
      </FormControl>
      {showCalendar && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1000,
            top: '80px',
            left: 0,
            transform: 'scale(1.2)',
            backgroundColor: '#f0f4f7',
            padding: '12px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <DateRangePicker
            ranges={internalDateRange}
            onChange={(ranges) =>
              handleSelectDateRange({ ranges, filterValues, setFilters, setInternalDateRange })
            }
            inputRanges={[]}
            staticRanges={customStaticRanges}
            className="ml-3"
            rangeColors={['#283593']}
          />
        </div>
      )}
    </div>
  );
};
