import { format, parseISO, addMinutes } from 'date-fns';
import { fetchOldestEventTime } from './loaders/metaApi';
import { getFilterParamsFromLocalStorage } from './localStorageUtils';

export const formatToUtc = (isoDateString) => {
  const date = parseISO(isoDateString);
  const utcDate = addMinutes(date, date.getTimezoneOffset());

  return format(utcDate, "dd/MM/yyyy, HH:mm:ss");
};

export const handleSelectDateRange = ({ ranges, filterValues, setFilters, setInternalDateRange }) => {
  const { selection } = ranges;

  setFilters({
    ...filterValues,
    start_date: selection.startDate ? format(selection.startDate, 'yyyy-MM-dd') : '',
    end_date: selection.endDate ? format(selection.endDate, 'yyyy-MM-dd') : new Date(),
  });

  setInternalDateRange([selection]);
};

export const formatDateRange = (date) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return '';
  }

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const createCustomStaticRanges = (firstRecordTime) => [
  {
    label: 'All Time',
    range: () => ({
      startDate: firstRecordTime ? new Date(firstRecordTime) : '',
      endDate: new Date(),
    }),
  },
];

export const loadInitialDates = async (localStorageKey) => {
  const { startDate, endDate } = getFilterParamsFromLocalStorage(localStorageKey);
  const oldestEventTime = await fetchOldestEventTime();

  const dateRange = {
    startDate: startDate || oldestEventTime,
    endDate: endDate || new Date(),
  };

  return {
    dateRange,
    firstRecordTime: oldestEventTime,
  };
};
