import { useEffect, useState } from 'react';
import {
  List,
  SelectColumnsButton,
  FilterButton
} from 'react-admin';
import { useNavigate, useLocation } from 'react-router-dom';
import { DocumentationButton } from '../../../helperComponents/DocumentationButton';
import { TablePagination } from '../../../helperComponents/TablePagination';
import { TitleAboveTable } from '../../../helperComponents/TitleAboveTable';
import { AggregateBySelect } from '../../../helperComponents/AggregateBySelect';
import { DateRangeFilter } from '../../../helperComponents/DateRangeFilter';
import { ExportAllDataButton } from './components/ExportAllDataButton';
import { ActionsWithoutCreate } from '../../settings/cron_settings/helpers/ActionsWithoutCreate';
import {
  handleRedirectionBasedOnFilters,
  getTableAggregationPath,
  getAggregateValue
} from '../../../../utils/aggregatedTablesUtils';
import { getMetaApiDataFilters } from '../../../../utils/filters/metaApiDataFilters';
import { fetchAccounts } from '../../../../utils/accountUtils';
import { MetaApiDataGrid } from './MetaApiDataGrid';
import { loadInitialDates } from '../../../../utils/dateUtils';

export const MetaApiDataList = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [accounts, setAccounts] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [firstRecordTime, setFirstRecordTime] = useState(null);

  useEffect(() => {
    handleRedirectionBasedOnFilters({
      location,
      navigate,
      path: getTableAggregationPath('meta_api_data'),
      aggregateField: getAggregateValue('meta_api_data'),
      localStorageName: 'meta_api_data',
    });
  }, [location.search, navigate]);

  useEffect(() => {
    const loadAccounts = async () => {
      const fetchedAccounts = await fetchAccounts();
      setAccounts(fetchedAccounts);
    };

    loadAccounts();
  }, []);

  useEffect(() => {
    const loadDates = async () => {
      const { dateRange, firstRecordTime } = await loadInitialDates('meta_api_data');
      setDateRange(dateRange);
      setFirstRecordTime(firstRecordTime);
    };

    loadDates();
  }, [localStorage.getItem('aggregate')]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-end">
        <TitleAboveTable title="Meta API Data" />
      </div>
      <List
        {...props}
        title=" "
        actions={<ActionsWithoutCreate
          showExportButton={false}
          showFilterButton={false}
          AdditionalButton={
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between' }}>
                <DateRangeFilter dateRange={dateRange} firstRecordTime={firstRecordTime} />
                <DocumentationButton reference="meta-api-documentation" label="Meta Data API Documentation" />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AggregateBySelect tableName='meta_api_data' localStorageName='meta_api_data' />
                <SelectColumnsButton preferenceKey="meta_api_data.datagrid" />
                <ExportAllDataButton />
                <FilterButton/>
              </div>
            </div>
          }
        />}
        pagination={<TablePagination />}
        perPage={25}
        filters={getMetaApiDataFilters(accounts)}
        sort={{ field: 'event_time', order: 'DESC' }}
      >
        <MetaApiDataGrid />
      </List>
    </>
  );
};
