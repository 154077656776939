import {
  SelectInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import { CustomPaper } from '../../../../helperComponents/CustomPaper';
import { useAffiliates } from '../../../../../hooks/useAffiliates';
import {
  TRACKCB_EVENT_TYPES,
  AFFILIATE_OPTIONS_TRACKCB_LIST,
  AFFILIATE_OPTIONS_TRACKCB,
  EVENT_TYPE_OPTIONS,
  START_DATE_OPTIONS,
} from '../../../../../config/constants/apiConstants';
import { JsonBooleanInput } from '../../../../helperComponents/JsonBooleanInput';

export const TrackCBTab = () => {
  const affiliates = useAffiliates();

  return (
    <>
      <ReferenceInput
        label="Brand ID"
        source="brand_id"
        reference="active_entities/brands"
        sort={{ field: 'brand_id', order: 'ASC' }}
      >
        <AutocompleteInput
          sx={{ width: '450px' }}
          optionText={(choice) => `${choice.brand_id} - ${choice.brand_name}`}
          optionValue="brand_id"
          helperText="Brand must be selected for TrackCB data to be captured."
          debounce={1000}
        />
      </ReferenceInput>
      <SelectInput
        source="affiliate_option_trackcb"
        label="Affiliate Option"
        choices={AFFILIATE_OPTIONS_TRACKCB_LIST}
        className="custom-input-width"
      />
      <FormDataConsumer>
        {({ formData }) => (
          <AutocompleteArrayInput
            label="Affiliate IDs"
            source="affiliate_ids_trackcb"
            choices={affiliates.map((affiliate) => ({
              id: affiliate.affiliate_id,
              name: `${affiliate.affiliate_id} - ${affiliate.affiliate}`,
            }))}
            PaperComponent={(props) => (
              <CustomPaper
                {...props}
                width="670px"
                elevation={8}
                margin="0 0 0 150px"
              />
            )}
            style={{ minWidth: '600px' }}
            disabled={formData.affiliate_option_trackcb !== AFFILIATE_OPTIONS_TRACKCB.LIST}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          const isAffiliateInputDisabled = formData.affiliate_option_trackcb !== AFFILIATE_OPTIONS_TRACKCB.NONE &&
                                           formData.affiliate_option_trackcb !== AFFILIATE_OPTIONS_TRACKCB.ALL;
          return (
            <AutocompleteArrayInput
              label="Exclude Affiliate IDs"
              source="excluded_affiliate_ids_trackcb"
              choices={affiliates.map(affiliate => ({
                id: affiliate.affiliate_id,
                name: `${affiliate.affiliate_id} - ${affiliate.affiliate}`,
              }))}
              PaperComponent={props => (
                <CustomPaper
                  {...props}
                  width="670px"
                  elevation={8}
                  margin="0 0 0 150px"
                />
              )}
              style={{ minWidth: '600px', paddingBottom: isAffiliateInputDisabled ? '0px' : '26px'}}
              disabled={isAffiliateInputDisabled}
              helperText={isAffiliateInputDisabled && (
                <span style={{ color: '#00000099' }}>Exclude Affiliate IDs is disabled. To enable, select All Data or Tune All Affiliates from Affiliate Option field.</span>
              )}
            />
          );
        }}
      </FormDataConsumer>
      <SelectInput
        source="event_type_option_trackcb"
        label="Event Type Option"
        choices={EVENT_TYPE_OPTIONS}
        className="custom-input-width"
      />
      <FormDataConsumer>
        {({ formData }) => (
          <AutocompleteArrayInput
            label="Event Types"
            source="event_types_trackcb"
            choices={TRACKCB_EVENT_TYPES}
            PaperComponent={(props) => (
              <CustomPaper
                {...props}
                width="670px"
                elevation={8}
                margin="0 0 0 150px"
              />
            )}
            style={{ minWidth: '600px' }}
            disabled={formData.event_type_option_trackcb !== 1}
          />
        )}
      </FormDataConsumer>
      <div className="d-flex">
        <JsonBooleanInput 
          source="add_trackcb_event_id"
          jsonSource="event_id_settings"
          label="Add Event ID (VID-TID-CustomerID-OrderID)"
        />
        <BooleanInput source="add_trackcb_external_id" label="Add External ID (VID-TID-CustomerID)"/>
        <BooleanInput source="add_trackcb_order_id" label="Add Custom Data Order ID (Order ID)"/>
      </div>
      <NumberInput
        source="payout_percentage_trackcb"
        step={0.01}
        label="Sales Amount Percentage"
        helperText="Enter sales amount percentage as a decimal (e.g., 0.50 for 50%). This value will be taken only from Sales Amount."
        className="custom-input-width"
        defaultValue={1}
        required={true}
      />
      <SelectInput
        source="start_date_trackcb"
        label="Start Date for TrackCB data"
        choices={START_DATE_OPTIONS}
        className="custom-input-width"
        helperText="When creating Pixel settings for the first time, Select Last 7 Days or Current. Once initial data is captured, either for Current or Last 7 days, this setting will change to Last Record Time. Last Record Time (within 7 days) will ensure all records are captured during any occurrence of downtime or delay."
      />
    </>
  );
};
