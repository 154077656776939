import {
  TextInput,
  SelectInput,
  AutocompleteInput,
  NumberInput,
  AutocompleteArrayInput
} from 'react-admin';
import { eventNames } from '../../helpers/constants';
import { dataSourceOptions } from '../../config/constants/apiConstants';

export const getMetaApiDataFilters = (accounts) => [
  <TextInput
    source="q"
    label="ID, Pixel ID, Settings ID, Transaction ID, Campaign ID, Order ID, Event ID"
    alwaysOn
    sx={{ width: '700px' }}
  />,
  <SelectInput
    source="data_source"
    label="Data Source"
    choices={dataSourceOptions}
    className="input-width-xs"
    alwaysOn
    emptyText="None"
    emptyValue="None"
  />,
  <TextInput
    source="pixel_id"
    label="FB Pixel ID"
    className="input-width-md"
    alwaysOn
    resettable={true}
  />,
  <AutocompleteInput
    source="account_name"
    label="Account Name"
    choices={accounts}
    optionText="name"
    optionValue="id"
    className="input-width-lg"
  />,
  <TextInput
    source="campaign_id"
    label="Campaign ID"
    className="input-width-md"
  />,
  <AutocompleteArrayInput
    source="event_name"
    label="Event Name"
    choices={eventNames}
  />,
  <NumberInput
    source="value"
    label="Value"
    className="input-width-md"
  />,
  <NumberInput
    source="settings_id"
    label="Settings ID"
    className="input-width-xs"
  />
];

export const getMetaApiDataAggregatedFilters = (accounts) => [
  <TextInput
    source="q"
    label="ID, Pixel ID, Settings ID, Transaction ID, Campaign ID, Order ID, Event ID"
    alwaysOn
    sx={{ width: '700px' }}
  />,
  <SelectInput
    source="data_source"
    label="Data Source"
    choices={dataSourceOptions}
    className="input-width-sm"
    alwaysOn
    emptyText="None"
    emptyValue="None"
  />,
  <TextInput
    source="pixel_id"
    label="FB Pixel ID"
    className="input-width-md"
    alwaysOn
    resettable={true}
  />,
  <NumberInput
    source="settings_id"
    label="Settings ID"
    className="input-width-xs"
  />
];
