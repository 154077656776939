import { httpClient } from '../config';

export const fetchAccounts = async () => {
  try {
    const dataResponse = await httpClient('/access/data');
    const accounts = JSON.parse(dataResponse.body).accountData.map(
      ({ id, account_name }) => ({ id, name: account_name, value: id })
    );
    return accounts;
  } catch (error) {
    console.error('Failed to fetch accounts:', error);
    return [];
  }
};
